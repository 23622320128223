import React, { ReactElement } from 'react';
import './Rules.scss';

const Et: React.FC = (): ReactElement => {
  return (
    <div className="Et rules-wrapper">
      <h2>KAMPAANIAREEGLID</h2>
      <ol>
        <li>
          Kampaania <span className="bold">“Küpsista või küpseta?”</span> on
          alates <span className="bold">26.03.2024</span> kuni{' '}
          <span className="bold">30.04.2024</span> toimuv tarbijamäng (edaspidi
          kampaania), mida korraldab Fazer Eesti OÜ, (asukoht: Pärnu maantee
          158/1, Tallinn; edaspidi korraldaja).
        </li>
        <li>
          Kampaania läbiviimise kord on kehtestatud korraldaja poolt
          käesolevates reeglites. Kõik korraldaja otsused kampaania läbiviimisel
          on lõplikud ning kõigile kampaanias osalejatele kohustuslikud.
        </li>
        <li>
          Kampaaniatooted on:
          <span className="bold block-span">
            Jyväshyvä küpsised šokolaaditükkidega 350g EAN 6416453073494;{' '}
          </span>
          <span className="bold block-span">
            Jyväshyvä küpsised kardemoni ja kaneeli 320g EAN 6416453072466;
          </span>
          <span className="bold block-span">
            Jyväshyvä 100% kaerajahust kaeraküpsised 350g EAN 6416453073432{' '}
          </span>
          (edaspidi tooted).
        </li>
        <li>
          <p className="bold">
            Kampaanias osalemiseks tuleb osta vähemalt üks Jyväshyvä toode ja
            registreerida ostutšeki number internetis kampaanialehel{' '}
            <a href="https://fazer.ee">www.fazer.ee</a> Kampaanias osalemine on
            tasuta. Auhinna kättesaamiseks tuleb kampaania korraldajale esitada
            ostutšekk.
          </p>
          <p>
            Loosimises osalemiseks võib ühe ostutšeki numbri esitada ainult ühe korra. Sama ostutšeki mitmekordsel registreerimisel osaleb ainult esimesena saabunud registreering.
          </p>
          <p className="bold">Isikuandmete töötlemise kord</p>
          <p>
            Fazer Eesti OÜ töötleb kampaania käigus osaleja poolt esitatud
            isikuandmeid vastavalt Eesti Vabariigis kehtivatele seadustele
            kampaania läbiviimise eesmärgil.
          </p>
          <p>
            Kampaanias osaleja annab Fazer Eesti OÜ-le nõusoleku töödelda
            esitatud andmeid järgnevatel eesmärkidel:
          </p>
          <p>
            <span className="block-span">
              kampaania tulemuste kohta kokkuvõtete tegemiseks;{' '}
            </span>
            <span className="block-span">
              kampaaniaga seotud koostööpartneritele info edastamiseks;
            </span>{' '}
            <span className="block-span">
              võitjate välja loosimiseks ja nende teavitamiseks;
            </span>{' '}
            <span className="block-span">
              võitjate nimede avaldamiseks kampaania veebilehel.
            </span>
          </p>
          <p>
            Kampaanias osalejatel on õigus saada lisainfot Fazer Eesti OÜ poolt
            isikuandmete töötlemise kohta. Selleks tuleb saata e-kiri
            aadressile: <a href="mailto:info@fazer.ee">info@fazer.ee</a>{' '}
          </p>
          <p>
            Isikuandmete vastutav töötleja on Fazer Eesti OÜ, registrikood
            10057691, Pärnu maantee 158/1, Tallinn 11317; tel 6502421;{' '}
            <a href="mailto:info@fazer.ee">info@fazer.ee</a>
          </p>
          <p>
            Kogutud andmeid säilitatakse kolm kuud alates kampaania
            lõpukuupäevast ehk kuni 01.08.2024. Võitjate täielik nimekiri on
            veebilehel üleval kuni 31.05.2024.{' '}
          </p>
        </li>
        <li>
          <p>
            <span className="bold">02.05.2024</span> kell 12.00 loositakse
            kõikide kampaanias osalejate vahel välja{' '}
            <span className="bold">
              3 KitchenAid Artisan lauamikserit väärtusega 1245€. Kogus 3 tükki,
              värvus beež. Auhinnafondi väärtus 1245€
            </span>
            .
          </p>
          <p>
            Auhinna loosimises osalevad kõik veebis registreerunud isikud
            kampaania reeglites esitatud perioodil (alates keskkonna
            avanemisest). Võitjat informeeritakse telefoni või meili teel.
            Loosimise juures viibib vähemalt üks Fazer Eesti OÜ esindaja.
          </p>
        </li>
        <li>
          Auhinna võitja nimi avaldatakse kampaania veebilehel aadressil{' '}
          <a href="http://www.fazer.ee">www.fazer.ee</a> hiljemalt loosimisele
          järgneval kolmandal tööpäeval. Võitjaga võetakse korraldaja esindaja
          poolt ühendust andmete ja auhinna kättetoimetamisviisi täpsustamiseks.
        </li>
        <li>
          Juhul, kui auhinna võitja ei vasta korraldaja poolt kehtestatud
          kampaania tingimustele või kui ta ei ole alles hoidnud toote
          ostutšekki, millel oleva numbri ta edastas kampaania korraldajale
          veebi vahendusel, on õigus auhind uuesti välja loosida järgmisele
          isikule. Samuti juhul, kui auhinna võitja ei ole hiljemalt ühe nädala
          jooksul alates, tema kui auhinna võitja nime avalikustamisest
          kampaania veebilehel aadressil{' '}
          <a href="http://www.fazer.ee">www.fazer.ee</a> olnud veebis esitatud
          kontaktandmetel kättesaadav andmete täpsustamiseks, on korraldajal
          õigus jätta talle auhind üle andmata.
        </li>
        <li>
          Korraldajalt ükskõik missuguse kampaania auhinna saaja annab loa
          korraldajale kasutada võitja nime meedias, reklaamides,
          PR-kampaaniates jms üritustel ilma võitjale selle eest täiendavat tasu
          maksmata ja vastavat kokkulepet sõlmimata.
        </li>
        <li>
          Kampaania käigus kampaanias osaleja poolt võidetud auhinda ei asendata
          võitja nõudmisel teist liiki auhinnaga ega hüvitata võitjale auhinna
          maksumust rahas, v.a juhul, kui auhind asendatakse teise samaväärse
          auhinnaga korraldaja otsusel.{' '}
        </li>
        <li>
          Auhinna võitjale üleandmisega seotud riiklikud maksud tasub korraldaja
          Eesti Vabariigi seadustes sätestatud korras ja tähtaegadel.{' '}
        </li>
        <li>
          Kampaanias osaleja poolt kampaania tingimuste eiramise või
          korraldajale enda kohta valede või ebatäpsete andmete esitamise
          korral, samuti muude ebaõnnestumiste korral (korraldajal ei õnnestu
          auhinna võitjaga kontakti saada), mis ei ole tingitud korraldaja
          süüst, ei vastuta korraldaja kampaanias osaleja auhindade loosimisest
          või auhinna kätteandmisest kõrvalejäämise eest.{' '}
        </li>
        <li>
          Vääramatu jõu (force majeure) asjaolude ilmnemisel on korraldajal
          ühepoolne õigus katkestada kampaania ja jätta auhinnad välja loosimata
          ja/või auhinnad võitjatele üle andmata, teatades sellest koheselt
          kampaanias osalejatele meedia vahendusel.
        </li>
        <li>
          Kõik kampaaniast tulenevad vaidlused lahendatakse vastavalt Eesti
          Vabariigi seadusandlusele.
        </li>
        <li>
          Kõik pretensioonid seoses kampaania korraldamise ja/või läbiviimisega
          tuleb saata kirjalikult kampaania korraldaja asukohta: Pärnu maantee
          158/1, 11317 Tallinn märksõnaga “KAMPAANIA" hiljemalt 01.05.2024.
          Kaebused, mis esitatakse peale nimetatud kuupäeva, ei võeta menetluse.
          Laekunud pretensioonidele vastatakse 3 tööpäeva jooksul. Kampaania
          lisainfo telefonil 650 2421;{' '}
          <a href="mailto:info@fazer.ee">info@fazer.ee</a>{' '}
        </li>
      </ol>
    </div>
  );
};

export default Et;
