import React, { ReactElement } from 'react';
import './Main.scss';

// import Prizes from '../../assets/images/banana_prizes.png';
import Cookies from '../../assets/images/cookies.png';
import CookiesMobile from '../../assets/images/cookies_mobile.png';

import { HashLink } from 'react-router-hash-link';
import Rules from '../../components/Rules/Rules';
import Winners from '../../components/winners/Winners';
import Register from '../../components/register/Register';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/images/logo.png';

const Main: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="Main">
      <section className="banner">
        <div className="banner-margins">
          <div className="columns">
            <div className="banner-header-mobile">
              <h1>KÜPSISTA</h1>
              <h2>VÕI KÜPSETA!</h2>
              <HashLink to="#register" className="button">
                {t('register')}
              </HashLink>
            </div>
            <div className="column is-two-fifths-mobile hidden-desktop">
              <img
                src={CookiesMobile}
                alt="fazer campaign product"
                className="banner-prize"
              />
            </div>
            <div className="banner-header column is-full-desktop is-three-fifths-mobile">
              <img src={Logo} alt="fazer logo" className="fazer-logo" />
              <h1>KÜPSISTA</h1>
              <h2>VÕI KÜPSETA!</h2>
              <p>
                <span className="block-span">
                  Osta ükskõik millist Jyväshyvä küpsist,
                </span>{' '}
                <span className="block-span">
                  registreeri ostutšekk <span className="bold">fazer.ee</span>{' '}
                  lehel
                </span>{' '}
                <span className="block-span">
                  ning osaled{' '}
                  <span className="bold">KitchenAid lauamikseri loosis.</span>{' '}
                </span>
              </p>
              <div className="campaign-period">
                <span>{t('campaign period')}</span>
              </div>
              <HashLink to="#register" className="button">
                {t('register')}
              </HashLink>
            </div>
            <div className="column is-half-mobile hidden-mobile">
              <img
                src={Cookies}
                alt="fazer campaign product"
                className="banner-prize"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <section className="header">
        <Header />
      </section> */}

      <section className="register-form">
        <div className="container">
          <div className="columns">
            <div className="column">
              <Register />
            </div>
          </div>
        </div>
      </section>
      <section className="rules-section" id="rules">
        <div className="container">
          <Rules />
        </div>
      </section>
      <section className="winners-section" id="winners">
        <div className="container">
          <Winners />
        </div>
      </section>
    </div>
  );
};

export default Main;
